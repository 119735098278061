import './App.css';
import RouterPage from './routes/Router'


function App() {
  
  return (
    <> 
      <RouterPage />
    
    </>
  );
}

export default App;
