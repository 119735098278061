import React from "react";
function Loader() {
  return (
    <>
      <div className="progress">
        <div className="indeterminate"></div>
      </div>
    </>
  );
}

export default Loader;
